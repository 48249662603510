import * as React from "react"
import { useEffect, useState } from "react"
import LinearProgress from "@mui/material/LinearProgress"
import { SxProps } from "@mui/material"
import { Theme } from "@mui/material/styles"

type ProgressProps = {
  delay?: number
  color?: string
  sx?: SxProps<Theme>
}

export default function ProgressInterstitial({ delay=5000, color="red", sx}: ProgressProps) {
  const [progress, setProgress] = useState(0)
  const interval = 25
  const trackColor = "#ddd"
  useEffect(() => {
    const increment = 115 / (delay / interval)
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        const newProgress = oldProgress + increment
        if (newProgress >= 100) {
          clearInterval(timer)
          return 100
        }
        return newProgress
      })
    }, interval)

    return () => clearInterval(timer)
  }, [delay])

  return (
    <LinearProgress
      variant='determinate'
      value={progress}
      sx={{
        backgroundColor: trackColor,
        "& .MuiLinearProgress-bar": {
          backgroundColor: color // Your desired color
        },
        "& .MuiLinearProgress-root": {
          backgroundColor: trackColor // Track color
        },
        ...sx
      }}
    />
  )
}
