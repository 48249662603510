import { ReactNode } from 'react'
import Interstitial from "../../warmlink/components/interstitials/Interstitial"
import BlankLayout from "../../@core/layouts/BlankLayout"

const WrapstitialPage = () => {
  return <Interstitial type='wrap'/>
}

WrapstitialPage.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>
WrapstitialPage.authGuard = false
WrapstitialPage.guestGuard = false

export default WrapstitialPage
