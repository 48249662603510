/**
 * Interstitial 4:
 * - Shows options avatar again to add more trust like an endorsement
 * - Show optional image in a bounding box such as an offer logo (from store card?)
 * - One subheader (remove subheader2)
 * - Ensure all items are submitted by caller
 */
import * as React from "react"
import { useEffect, useRef, useState } from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { InterstitialProps } from "./Interstitial"
import TitleFramer from "./TitleFramer"
import ProgressInterstitial from "./ProgressInterstitial"
import {WRAPLINK_AD_SKIP_BUTTON, WRAPLINK_AD_VISIT_BUTTON} from "@wildeye/shared"

// Debug switches
const DEBUG_TITLE_AREA = false
const DEBUG_TITLEBG = false
const DEBUG_BORDERS = false

// Title config
const TITLE_DURATION = 1200 // milliseconds to display title
const MAX_TITLE_FONT_SIZE = 500 // This is in pixels
const TITLE_BG_COLOR = "" // "white" // specify title area color or falsy, can be dynamic from wrapad
const TEXT_BORDER_PX = 3 // can be dynamic from wrapad
const TEXT_BORDER_COLOR = "#FFF" // can be dynamic from wrapad

// Title areas based on orientation
const WIDTH_FOR_LANDSCAPE = ["100%", "66%"]
const HEIGHT_FOR_LANDSCAPE = ["34%", "64%"]
// Button config
const WarmlinkButtonColor = "#8B4513" // SaddleBrown
const WarmlinkButtonHoverColor = "#ad5517"
const WarmlinkButtonActiveColor = "#c7621c"
const SkipButtonColor = "#B8860B" // DarkGoldenrod
const SkipButtonHoverColor = "#DAA520" // Goldenrod
const SkipButtonActiveColor = "#f0b522"

export default function Interstitial4({ wrap, skip, visit }: InterstitialProps) {
  const [landscape, setLandscape] = useState(true)
  const [imageSrc, setImageSrc] = useState("")
  const [imageLoaded, setImageLoaded] = useState(false)
  const [fontSize, setFontSize] = useState<number>(0) // Start with a large base font size
  const containerRef = useRef<HTMLDivElement>(null)
  const imageBoxRef = useRef<HTMLDivElement>(null)
  const textRef = useRef<HTMLSpanElement>(null) // Specify the correct type here

  // Determine image src when wrap is first loaded and when viewport resizes
  useEffect(() => {
    if (!wrap) return
    console.log(`wrap: ${JSON.stringify(wrap)}`)
    const updateImageSrc = () => {
      const isLandscape = window.innerWidth > window.innerHeight
      setLandscape(isLandscape)
      const imageUrl = (isLandscape ? wrap?.wrapad?.image_bg_land : wrap?.wrapad?.image_bg_port) || ""
      console.log(`imageUrl: ${JSON.stringify(imageUrl)}`)
      setImageSrc(imageUrl)
    }
    updateImageSrc() // Set initial image and add event listener for resizing
    window.addEventListener("resize", updateImageSrc)
    return () => window.removeEventListener("resize", updateImageSrc) // Clean up event listener on component unmount
  }, [wrap])

  //------------------------
  const loadImage = (src: string) => {
    const img = new Image()
    img.src = src
    img.onload = () => setImageLoaded(true)
  }

  useEffect(() => {
    loadImage(imageSrc)
  }, [imageSrc])

  useEffect(() => {
    if (imageLoaded) {
      const adjustFontSize = () => {
        if (!containerRef.current || !textRef.current) return

        // console.log("START ADJUST FONT SIZE")

        // Set a large initial font size for testing bounds
        let currentFontSize = MAX_TITLE_FONT_SIZE
        textRef.current.style.fontSize = `${currentFontSize}px`

        // Check and adjust the font size until it fits within the container's width and height
        while (
          (textRef.current.scrollWidth > containerRef.current.clientWidth ||
            textRef.current.scrollHeight > containerRef.current.clientHeight) &&
          currentFontSize > 10
        ) {
          currentFontSize -= 1
          textRef.current.style.fontSize = `${currentFontSize}px`
        }

        // console.log("FINAL font size set:", currentFontSize)
        setFontSize(currentFontSize)
      }

      // Delay the initial call to ensure the DOM is rendered
      requestAnimationFrame(() => {
        setTimeout(adjustFontSize, 50) // Adjust delay if needed to account for rendering
      })

      window.addEventListener("resize", adjustFontSize)

      return () => {
        window.removeEventListener("resize", adjustFontSize)
      }
    }
    return undefined
  }, [imageLoaded, landscape])

  if (!imageLoaded) return <Box sx={{ width: "100vw", height: "100vh" }} />

  return (
    <Box sx={styles.outerBox}>
      <Box ref={imageBoxRef} sx={styles.imageBox(landscape)}>
        {/* Image */}

        <img src={imageSrc} style={styles.img as React.CSSProperties} alt='Wraplink Ad' />

        {/* Overlays */}

        {TITLE_BG_COLOR && (
          <Box sx={{ ...styles.titleOverlayStyle(landscape), background: TITLE_BG_COLOR || "transparent" }} />
        )}
        {DEBUG_TITLE_AREA && <Box sx={styles.titleOverlayStyle(landscape)} />}

        {/* Animated Text */}

        <Box sx={styles.titleContainer(landscape)} ref={containerRef}>
          <TitleFramer
            ref={textRef}
            title={wrap?.wrapad?.title || ""}
            duration={TITLE_DURATION}
            sx={{
              background: DEBUG_TITLEBG ? "red" : "transparent",
              ...styles.dynamicText(landscape),
              fontSize: `${fontSize}px`,
              padding: "5px 05px"
            }}
          />
        </Box>
      </Box>

      {/* Progress */}

      <Box
        sx={{ width: imageBoxRef.current?.clientWidth || 0, py: 0, my: 0, left: imageBoxRef.current?.offsetLeft || 0 }}
      >
        <ProgressInterstitial delay={wrap?.delay || 4000} sx={{ height: 10 }} color='black' />
      </Box>

      {/* Buttons */}

      <Box sx={styles.buttons(imageBoxRef)}>
        <Button size='large' variant='contained' onClick={visit} sx={styles.visitButton}>
          {wrap?.wrapad?.button_visit || WRAPLINK_AD_VISIT_BUTTON}
        </Button>
        <Button size='large' variant='contained' onClick={skip} sx={styles.skipButton}>
          {wrap?.wrapad?.button_skip || WRAPLINK_AD_SKIP_BUTTON}
        </Button>
      </Box>
    </Box>
  )
}

const styles = {
  outerBox: {
    alignItems: "center",
    border: DEBUG_BORDERS ? "1px solid red" : "none",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "center",
    overflow: "hidden",
    padding: "10px 10px",
    position: "relative",
    width: "100vw"
  },
  imageBox: (landscape: boolean) => ({
    border: DEBUG_BORDERS ? "1px solid blue" : "none",
    flexShrink: 1, // Allow the box to shrink if needed to fit within the container
    flexBasis: "auto", // Allow the height to adjust based on content
    overflow: "hidden",
    position: "relative",
    display: "flex",
    margin: 0,
    padding: 0,
    flexDirection: "column",
    maxWidth: "100%",
    maxHeight: "100%", // Constrain height to viewport
    aspectRatio: landscape ? "16 / 9" : "9 / 16"
  }),
  img: {
    height: "100%",
    objectFit: "contain",
    width: "100%"
  },
  titleOverlayStyle: (landscape: boolean) => ({
    backgroundColor: "#F0F4",
    height: HEIGHT_FOR_LANDSCAPE[+landscape],
    left: 0,
    pointerEvents: "none",
    position: "absolute",
    top: 0,
    width: WIDTH_FOR_LANDSCAPE[+landscape]
  }),
  titleContainer: (landscape: boolean) => ({
    alignItems: "center",
    border: DEBUG_BORDERS ? "1px solid green" : "none",
    display: "flex",
    height: HEIGHT_FOR_LANDSCAPE[+landscape],
    justifyContent: "center",
    left: 0,
    overflow: "hidden",
    position: "absolute",
    top: 0,
    width: WIDTH_FOR_LANDSCAPE[+landscape]
  }),
  dynamicText: (landscape: boolean) => ({
    color: "black",
    fontWeight: 900,
    fontFamily: "Poppins, sans-serif",
    lineHeight: 1.2,
    marginLeft: 3,
    textAlign: landscape ? "left" : "center",
    textShadow: `-${TEXT_BORDER_PX}px -${TEXT_BORDER_PX}px 0 ${TEXT_BORDER_COLOR},
      ${TEXT_BORDER_PX}px -${TEXT_BORDER_PX}px 0 ${TEXT_BORDER_COLOR},
      -${TEXT_BORDER_PX}px ${TEXT_BORDER_PX}px 0 ${TEXT_BORDER_COLOR},
      ${TEXT_BORDER_PX}px ${TEXT_BORDER_PX}px 0 ${TEXT_BORDER_COLOR}`,
    textTransform: "uppercase"
  }),
  buttons: (wrapperRef: any) => ({
    alignItems: "center",
    display: "flex",
    gap: "1%",
    justifyContent: "center",
    mt: 1,
    width: wrapperRef.current?.clientWidth
  }),
  visitButton: {
    backgroundColor: WarmlinkButtonColor,
    flex: 1, // Ensures both buttons take equal width,
    fontSize: { xs: "3vw", md: "2vw" },
    lineHeight: 1.5, // Adjust line height for text
    minHeight: "unset", // Remove the default min height for MUI buttons
    "&:hover": { backgroundColor: WarmlinkButtonHoverColor },
    "&:active": { backgroundColor: WarmlinkButtonActiveColor }
  },
  skipButton: {
    backgroundColor: SkipButtonColor,
    flex: 1, // Ensures both buttons take equal width
    fontSize: { xs: "3vw", md: "2vw" },
    lineHeight: 1.5, // Adjust line height for text
    minHeight: "unset", // Remove the default min height for MUI buttons
    "&:hover": { backgroundColor: SkipButtonHoverColor },
    "&:active": { backgroundColor: SkipButtonActiveColor }
  }
}
