import React, { useEffect, useRef, useState } from "react"
import { Click } from "@prisma/client"
import { INTERSTITIAL_DELAY, isDevelopment, WrapIncludes } from "@wildeye/shared"
import { useLazyGetClickQuery, useLazyGetWrapWithAdQuery } from "../../../store/apps/api/warmlink"
import { useRouter } from "next/router"
import Interstitial4 from "./Interstitial4"
import FontFaceObserver from "fontfaceobserver"

export interface InterstitialProps {
  type?: "warm" | "wrap"
  wrap?: WrapIncludes | null
  skip?: () => void
  visit?: () => void
  countdown?: number
}

export enum ExitType {
  redirect = "redirect",
  skip = "skip",
  visit = "visit"
}

export default function Interstitial({ type }: InterstitialProps) {
  const router = useRouter()
  const { query } = router
  const stop = query.stop === ""
  const { slugs } = router.query as { slugs: string[] | undefined }
  const code = slugs?.[0]
  const isWrap = type == "wrap"
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [trigger, { data, isLoading, error }] = isWrap ? useLazyGetWrapWithAdQuery() : useLazyGetClickQuery()
  const [wrap, setWrap] = useState<WrapIncludes | null>(null)
  const [initialized, setInitialized] = useState(false)
  const [isClient, setIsClient] = useState(false)
  const [fontLoaded, setFontLoaded] = useState(false) // New state for font loading
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [countdown, setCountdown] = useState<number | undefined>(undefined)
  const [startTime, setStartTime] = useState<number>(0)
  const timerRef = useRef<number | null>(null)
  const intervalRef = useRef<number | null>(null) // Ref for countdown interval

  useEffect(() => {
    setIsClient(true)
    const poppinsObserver = new FontFaceObserver("Poppins", { weight: 900 })
    // Wait for the font to load
    poppinsObserver.load().then(
      () => {
        console.log("Poppins font has loaded successfully!")
        setFontLoaded(true)
      },
      () => {
        console.warn("Poppins font failed to load.")
      }
    )
  }, [])

  useEffect(() => {
    if (isClient && fontLoaded && initialized) {
      setStartTime(Date.now())
    }
  }, [isClient, fontLoaded, initialized])

  // This loads the wrap/warm data when code is valid
  useEffect(() => {
    if (slugs) {
      trigger(slugs.join("/"))
    }
  }, [slugs, trigger])

  // Delay and redirect when wrap loaded
  useEffect(() => {
    const resetCountdownAndTimers = () => {
      if (timerRef.current) window.clearTimeout(timerRef.current)
      if (intervalRef.current) window.clearInterval(intervalRef.current)
      setCountdown(undefined) // Reset countdown state
    }

    const handleRedirect = async () => {
      try {
        if (initialized && data && !isLoading && !error) {
          const wrap = data as unknown as WrapIncludes
          setWrap(wrap)
          const click = data as unknown as Click
          const link = isWrap ? wrap.link : click.affiliate_link
          const delay = isWrap ? wrap.delay : INTERSTITIAL_DELAY
          /**
           * Start internval countdown timer
           */
          const delayInSeconds = Math.ceil(delay / 1000)
          setCountdown(delayInSeconds)
          if (!stop) {
            /**
             * Begin timeout that triggers redirect
             */
            // Replace the current entry in the history stack with the home page URL so that when
            // user goes backwards they end up at Warmlink's home page
            window.history.replaceState(null, "", "/")
            window.history.pushState(null, "", "/")
            // wait for a short time before redirecting to desired location
            if (wrap?.wrapad) {
              timerRef.current = window.setTimeout(async () => {
                if (wrap) postGA4Event(startTime, wrap, ExitType.redirect)
                if (intervalRef.current) window.clearInterval(intervalRef.current)
                window.location.href = link
              }, delay)
            } else {
              window.location.href = link
            }
          } else if (timerRef.current) window.clearTimeout(timerRef.current)
        }
      } catch (err) {
        console.error("Error during navigation:", err)
      }
      return
    }

    handleRedirect().then(() => {})

    // Cleanup function to clear interval and timeout
    return resetCountdownAndTimers
  }, [data, isLoading, error, code, router])

  // Redirect to home if code is bad
  useEffect(() => {
    if (!isLoading && (error || !data)) {
      if (initialized) {
        if (!stop) handleSkip()
      }
    }
  }, [error, isLoading, data, router, initialized])

  // Set initialized when we get valid code
  useEffect(() => {
    if (code !== undefined) {
      setInitialized(true)
    }
  }, [code, query, router])

  function handleSkip() {
    if (wrap) postGA4Event(startTime, wrap, ExitType.skip)
    window.location.href = wrap?.link || "/"
  }

  function handleVisit() {
    if (timerRef.current !== null) {
      window.clearTimeout(timerRef.current)
      if (intervalRef.current !== null) {
        window.clearInterval(intervalRef.current) // Clear the interval on manual visit
      }
      if (wrap) postGA4Event(startTime, wrap, ExitType.visit)
      router.push(process.env.NEXT_PUBLIC_APP + "").then(() => {})
    }
  }

  // Send Wraplink GA4 event based on this Wraplink's display and behavior
  function postGA4Event(startTime: number, wrap: WrapIncludes, exit: ExitType) {
    if (typeof window !== "undefined" && typeof window.gtag === "function" && wrap) {
      const endTime = Date.now()
      const duration = endTime - startTime // MS
      // NOTE: It's fine to return "undefined" below which will cause the param to be ignored
      window.gtag("event", "wraplink", {
        debug_mode: isDevelopment(),
        duration: duration,
        exit: exit,
        image_bg: wrap?.wrapad?.image_bg_land,
        image_bg_port: wrap?.wrapad?.image_bg_port,
        signups: wrap.signups,
        theme: wrap?.wrapad?.theme,
        title: wrap?.wrapad?.title,
        user_id: wrap.user_id,
        wrap_id: wrap.id,
        wrapad_id: wrap?.wrapad?.id
      })
    } else {
      // console.error("interstitial: gtag not available")
    }
  }

  if (!isClient || !fontLoaded) {
    return null
  }

  const click = data as unknown as Click
  const display = stop || isWrap || (data && !isLoading && click.offer_id !== "warmlink")
  // console.log(`display:${display} code:${code} stop:${stop} data:${JSON.stringify(data)} isLoading:${isLoading}`)
  if (!display) return null

  return <Interstitial4 wrap={data as unknown as WrapIncludes} visit={handleVisit} skip={handleSkip} />
}
