import React, { forwardRef } from "react"
import { motion } from "framer-motion"
import { SxProps } from "@mui/material"
import { Theme } from "@mui/material/styles"
import Box from "@mui/material/Box"

type TitleProps = {
  title: string
  duration: number // ms
  sx?: SxProps<Theme>
}

const TitleFramer = forwardRef<HTMLSpanElement, TitleProps>(({ title, duration, ...props }, ref) => {
  const words = title.split(" ")

  // Generate random durations and normalize to ensure they add up to `duration`
  const randomDurations = Array.from({ length: words.length }, () => Math.random())
  const totalRandom = randomDurations.reduce((sum, dur) => sum + dur, 0)
  const normalizedDurations = randomDurations.map(dur => ((dur / totalRandom) * duration) / 1000)

  // Calculate cumulative delays
  const delays = normalizedDurations.reduce((acc, dur, index) => {
    if (index === 0) {
      acc.push(0)
    } else {
      acc.push(acc[index - 1] + normalizedDurations[index - 1])
    }
    return acc
  }, [] as number[])

  return (
    <Box
      component='span' // Use `span` to match the HTMLSpanElement type
      ref={ref} // Attach the ref here so the parent can access it
      sx={{
        overflow: "hidden",
        textAlign: "left",
        width: "100%",
        whiteSpace: "pre-wrap", // Ensures line breaks are respected
        ...props.sx // Spread any additional sx styles passed in props
      }}
    >
      {title.split("\n").map((line, lineIndex) => (
        <React.Fragment key={lineIndex}>
          {line
            .trim()
            .split(" ")
            .map((word, index) => (
              <motion.span
                key={index}
                style={{
                  margin: "0 0.2em",
                  display: "inline-block",
                  transformOrigin: "center bottom"
                }}
                initial={{ opacity: 0, y: 20, scale: 1.5 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                transition={{
                  duration: normalizedDurations[index % normalizedDurations.length], // Reuse durations for words
                  delay: delays[index % delays.length],
                  ease: "easeOut"
                }}
              >
                {word}
              </motion.span>
            ))}
          {lineIndex < title.split("\n").length - 1 && <br />}
        </React.Fragment>
      ))}
    </Box>
  )
})

export default TitleFramer
